import React from "react"
import Layout from "../components/layout"
import { StyledFullheightContainer } from "../styles/global"
import styled from "styled-components"
import { ILocation } from "./../interface/location"

const Styled404Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const NotFoundPage: React.FC<{ location: ILocation }> = ({ location }) => (
    <Layout location={location}>
        <StyledFullheightContainer>
            <Styled404Wrapper>
                <h1 className="title">Fant ikke siden</h1>
                <p className="subtitle">
                    Du forsøkte å navigere til en side som ikke eksisterer..
                </p>
            </Styled404Wrapper>
        </StyledFullheightContainer>
    </Layout>
)

export default NotFoundPage
